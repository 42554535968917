@import url("icons/font-awesome.css"); /* Ref: https://fontawesome.com/search?o=r&m=free */
@import url("icons/bootstrap-icons.css"); /* Ref: https://icons.getbootstrap.com/#icons */
@import url("icons/unicons.css"); /* Ref: https://iconscout.com/unicons/explore/line */

[class*="fa-"],
[class*=" fa-"],
[class*="bi-"],
[class*=" bi-"],
[class*="uil-"],
[class*=" uil-"] {
	display: inline-block;
	line-height: inherit;
	font-display: swap;
}

[class*="fa-"]::before,
[class*=" fa-"]::before,
[class*="bi-"]::before,
[class*=" bi-"]::before,
[class*="uil-"]::before,
[class*=" uil-"]::before {
	display: inline-flex;
	align-self: center;
	justify-self: center;
	line-height: inherit;
}

.icon-lg {
	font-size: 1.3333333333333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}
.icon-2x {
	font-size: 2em;
}
.icon-3x {
	font-size: 3em;
}
.icon-4x {
	font-size: 4em;
}
.icon-5x {
	font-size: 5em;
}
.icon-fw {
	width: 1.2857142857142858em;
	text-align: center;
}

.icon-border {
	padding: 0.2em 0.25em 0.15em;
	border: solid 0.08em #eeeeee;
	border-radius: 0.1em;
}
.icon.pull-left {
	margin-right: 0.3em;
}
.icon.pull-right {
	margin-left: 0.3em;
}
.icon-spin {
	-webkit-animation: spin 2s infinite linear;
	-moz-animation: spin 2s infinite linear;
	-o-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
	}
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
	}
}
@-o-keyframes spin {
	0% {
		-o-transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(359deg);
	}
}
@-ms-keyframes spin {
	0% {
		-ms-transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(359deg);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
.icon-rotate-90 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.icon-rotate-180 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.icon-rotate-270 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	-webkit-transform: rotate(270deg);
	-moz-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	-o-transform: rotate(270deg);
	transform: rotate(270deg);
}
.icon-flip-horizontal {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
	-webkit-transform: scale(-1, 1);
	-moz-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	transform: scale(-1, 1);
}
.icon-flip-vertical {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
	-webkit-transform: scale(1, -1);
	-moz-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	-o-transform: scale(1, -1);
	transform: scale(1, -1);
}
.icon-stacked {
	position: relative;
	display: inline-block;
	width: 2em;
	height: 2em;
	line-height: 2em;
	vertical-align: middle;
}
.icon-stacked-1x,
.icon-stacked-2x {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
}
.rtl .icon-stacked-1x,
.rtl .icon-stacked-2x {
	left: auto;
	right: 0;
}
.icon-stacked-1x {
	line-height: inherit;
}
.icon-stacked-2x {
	font-size: 2em;
}
.icon-inverse {
	color: #ffffff;
}
